import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.sizes.large / 10}rem;
  margin: 0 auto;
  padding: 0 2rem;
`

export default Container
