import styled, { css } from 'styled-components'
import { theme } from 'common/styles'

export const SECTION_LAYOUT_TEXT = 'text'
export const SECTION_LAYOUT_WIDE = 'wide'

export const Section = styled.section`
  margin: 6rem auto;
  counter-reset: section;

  ${({ layout }) => {
    switch (layout) {
      case SECTION_LAYOUT_TEXT:
        return css`
          max-width: 58rem;
        `
      case SECTION_LAYOUT_WIDE:
        return css`
          max-width: 67.2rem;
        `
      default:
        return null
    }
  }}

  ${theme.mq.small.css`
    margin-top: 3.6rem;
  `}
`

export const PageContent = styled.div`
  margin: 8rem 0;

  ${theme.mq.small.css`
    margin-top: 11.5rem;
    
    ${props =>
      !props.isMobileVisible &&
      css`
        margin-top: 6rem;
      `}
  `}
`
