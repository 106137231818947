import React from 'react'
import Props from 'prop-types'
import { Link } from 'gatsby'
import { without, append } from 'ramda'
import { Location as LocationComp } from '@reach/router'

import SocialItems from 'components/Navigation/Social'
import MenuItems from './MenuItems'
import { Ul, Li, MobileMenuContainer, Social } from './styled'

class Menu extends React.Component {
  enableScroll = () => {
    const bodyEl = document.getElementsByTagName('body')[0]
    const bodyClasses = bodyEl.className.split(' ')
    bodyEl.className = without('no-scroll', bodyClasses).join(' ')
  }

  disableScroll = () => {
    const bodyEl = document.getElementsByTagName('body')[0]
    const bodyClasses = bodyEl.className.split(' ')
    bodyEl.className = append(
      'no-scroll',
      bodyClasses.filter(item => !!item)
    ).join(' ')
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.disableScroll()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.disableScroll()
    } else if (!this.props.isOpen && prevProps.isOpen) {
      this.enableScroll()
    }
  }

  render() {
    const { isOpen, onScrollClick, translates, ...props } = this.props

    return (
      <MobileMenuContainer isOpen={isOpen}>
        <Ul {...props}>
          <LocationComp>
            {router =>
              !['/', '/tw/', '/ko/', '/ja/'].includes(
                router.location.pathname
              ) && (
                <Li>
                  <Link
                    to={`/${translates.slug !== 'en' ? translates.slug : ''}`}
                  >
                    Home
                  </Link>
                </Li>
              )
            }
          </LocationComp>
          <MenuItems onScrollClick={onScrollClick} translates={translates} />
        </Ul>

        <Social>
          <SocialItems />
        </Social>
      </MobileMenuContainer>
    )
  }
}

Menu.propTypes = {
  isOpen: Props.bool.isRequired,
  onScrollClick: Props.func.isRequired,
  translates: Props.any.isRequired,
}

export default Menu
