import React from 'react'
import Props from 'prop-types'

import Container from 'components/Styled/Container'
import SocialItems from 'components/Navigation/Social'
import MenuItems from 'components/Navigation/MenuItems'
import { Footer, Content, Help, Copy, Menu, Social } from './styled'

const HomepageFooter = ({ translates }) => {
  const { contact, footer } = translates
  return (
    <Footer id="contact">
      <Container>
        <Content>
          {contact && <Help dangerouslySetInnerHTML={{ __html: contact }} />}

          <Menu>
            <MenuItems isFooterNav translates={translates} />
          </Menu>

          <Social>
            <SocialItems />
          </Social>
          {footer && <Copy>{footer}</Copy>}
        </Content>
      </Container>
    </Footer>
  )
}

HomepageFooter.propTypes = {
  translates: Props.shape({
    contact: Props.string.isRequired,
    footer: Props.string.isRequired,
  }).isRequired,
}

export default HomepageFooter
