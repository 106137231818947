import React from 'react'

const IconTwitter = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1144.000000, -2048.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 2008.000000)">
          <g transform="translate(1144.000000, 40.000000)">
            <path d="M15.88864,0 C7.12768,0 0,7.12768 0,15.88864 C0,24.64896 7.12768,31.77728 15.88864,31.77728 C24.64896,31.77728 31.77728,24.64896 31.77728,15.88864 C31.77728,7.12768 24.65024,0 15.88864,0 Z M22.97664,12.25216 C22.98368,12.4096 22.98752,12.56832 22.98752,12.72704 C22.98752,17.55968 19.31008,23.13024 12.58176,23.13024 C10.51648,23.13024 8.59392,22.52672 6.976,21.48864 C7.26208,21.52256 7.55328,21.53984 7.84832,21.53984 C9.56224,21.53984 11.13856,20.95488 12.3904,19.9744 C10.7904,19.94496 9.43936,18.88768 8.97408,17.43424 C9.1968,17.47648 9.42656,17.50016 9.66144,17.50016 C9.99488,17.50016 10.31872,17.45664 10.62528,17.3728 C8.95232,17.03744 7.69216,15.55968 7.69216,13.78688 C7.69216,13.77152 7.69216,13.75552 7.6928,13.7408 C8.1856,14.01408 8.74944,14.1792 9.34848,14.19776 C8.368,13.54304 7.72224,12.42304 7.72224,11.15456 C7.72224,10.48384 7.90208,9.85536 8.21696,9.3152 C10.01984,11.52832 12.71552,12.98368 15.75424,13.13728 C15.69152,12.86912 15.66016,12.59072 15.66016,12.30336 C15.66016,10.28416 17.29728,8.6464 19.31648,8.6464 C20.36864,8.6464 21.31776,9.09056 21.98592,9.80096 C22.81984,9.63712 23.60064,9.33376 24.30912,8.91328 C24.03392,9.76768 23.456,10.48384 22.69888,10.93696 C23.43936,10.84864 24.14592,10.6528 24.79936,10.3616 C24.31168,11.09376 23.69152,11.73824 22.97664,12.25216 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconTwitter
