import styled, { css } from 'styled-components'
import { theme } from 'common/styles'

export const H2 = styled.h2`
  font-size: 4.5rem;
  font-weight: bold;
  margin: 2.5rem 0;
  color: ${props => props.theme.colors.headings};

  ${props => props.theme.mq.small.css`
    margin: 1rem 0;
    font-size: ${props.large ? 4 : 3.2}rem;
  `}

  &:first-child {
    margin-top: 0;
  }
`

export const H2Mobile = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`

export const MobileContent = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }
`

export const H3 = styled.h3`
  font-size: 2.4rem;
  font-weight: 600;
  margin: 2.4rem 0;
  color: ${props => props.theme.colors.headings};

  &:first-child {
    margin-top: 0;
  }

  ${props =>
    props.numbered &&
    css`
      margin-left: 0rem;

      &:before {
        counter-increment: section;
        content: counters(section, '.');
        display: inline-block;
        width: 7rem;
        margin-left: -7rem;
      }
    `}

  ${props => props.theme.mq.small.css`
    margin: 2rem 0 2rem ${props.numbered ? '5rem' : 0};
    font-size: 2.4rem;

    ${props.numbered &&
      css`
        margin-left: 4rem;

        &:before {
          width: 4rem;
          margin-left: -4rem;
        }
      `}
  `}
`

export const H4 = styled.h4`
  font-size: 1.9rem;
  font-weight: 600;
  margin: 2.6rem 0;
  color: ${props => props.theme.colors.headings};
`

const listCommon = `
  line-height: 1.4;
  
  li {
    margin: 2.4rem 0;
  }
`

const UL_GAP = 4
const UL_GAP_MOBILE = 2

export const Ul = styled.ul`
  ${listCommon};

  margin-left: ${UL_GAP}rem;

  > li::before {
    color: ${theme.colors.text};
    margin-left: 0;
    display: inline-block;
    font-size: 3rem;
    line-height: 3rem;
    width: ${UL_GAP}rem;
    min-width: ${UL_GAP}rem;
    margin-left: -${UL_GAP}rem;
    content: '•';
  }

  ${props => props.theme.mq.small.css`
    margin-left: ${UL_GAP_MOBILE}rem;
    li::before {
      width: ${UL_GAP_MOBILE}rem;
      min-width: ${UL_GAP_MOBILE}rem;
      margin-left: -${UL_GAP_MOBILE}rem;
      position: relative;
      top: 2px;
    }
  `}
`

export const UlSecondary = styled.ul`
  margin: 0;
  margin-bottom: 3.5rem;

  > li {
    margin: 0;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &::before {
      display: none;
      top: 4px;
    }

    a {
      color: ${theme.colors.text};
      padding: 1.4rem 2rem 1.4rem 0;
      display: block;
      width: 100%;
      text-decoration: none;
      font-weight: 300;
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 9px;
        height: 2px;
        background: #c3bfc6;
        top: 50%;
        margin-top: -1px;
      }

      &:before {
        transform: rotate(45deg);
        transform-origin: right;
      }

      &:after {
        transform: rotate(-45deg);
        transform-origin: right;
      }
    }
  }
`

const OL_GAP = 7
const OL_GAP_MOBILE = 4

export const Ol = styled.ol`
  ${listCommon}
  counter-reset: subsection;

  > li::before {
    counter-increment: subsection;
    content: counter(section) '.' counter(subsection) ' ';
    display: inline-block;
    width: ${OL_GAP}rem;
    margin-left: -${OL_GAP}rem;
  }

  ${props => props.theme.mq.small.css`
    margin-left: ${OL_GAP_MOBILE}rem;
    li::before {
      width: ${OL_GAP_MOBILE}rem;
      margin-left: -${OL_GAP_MOBILE}rem;
    }
  `}
`

export const Paragraph = styled.p`
  font-size: ${props => (props.large ? '2rem' : 'inherit')};
  line-height: 1.5;

  ${props =>
    props.large &&
    props.theme.mq.small.css`
    font-size: 1.8rem;
  `}
`
