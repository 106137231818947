import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { theme } from 'common/styles'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body, #___gatsby, #___gatsby > * {
    width: 100%; height: 100%;
    padding: 0; margin: 0;
    scroll-behavior: smooth;
    scroll-padding-top: 1rem;
  }

  html {
    font-size: ${10 / 16}em;
  }

  body {
    font-family: ${theme.font};
    font-size: 1.8rem;
    color: ${theme.colors.text};
    text-rendering: optimizeLegibility;
    background-color: #fff;

    ${theme.mq.small.css`
      font-size: 1.6rem;

      &.no-scroll {
        overflow-y: hidden;
      }
    `}
  }

  #___gatsby {
    &, & > * {
      display: flex;
      flex-direction: column;
    }

    & > * > * {
      flex-shrink: 0;
    }
  }

  div, nav, header, main, section, footer, figure {
    position: relative;
  }

  a {
    color: inherit;
  }

  ul, ol, li {
    margin: 0; padding: 0;
  }

  ul, ol {
    list-style: none;
  }
`

export default GlobalStyle
