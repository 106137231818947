import React from 'react'

const IconInstagram = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1200.000000, -2048.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 2008.000000)">
          <g transform="translate(1200.000000, 40.000000)">
            <path d="M20.7128889,8.49066667 L11.2010667,8.49066667 C9.68284444,8.49066667 8.44764444,9.72586667 8.44764444,11.2440889 L8.44764444,20.7559111 C8.44764444,22.2741333 9.68284444,23.5096889 11.2010667,23.5096889 L20.7128889,23.5096889 C22.2311111,23.5096889 23.4666667,22.2744889 23.4666667,20.7559111 L23.4666667,11.2440889 C23.4666667,9.72586667 22.2314667,8.49066667 20.7128889,8.49066667 Z M15.9569778,20.9443556 C13.2309333,20.9443556 11.0129778,18.7264 11.0129778,16 C11.0129778,13.2739556 13.2309333,11.056 15.9569778,11.056 C18.6833778,11.056 20.9013333,13.2739556 20.9013333,16 C20.9013333,18.7260444 18.6830222,20.9443556 15.9569778,20.9443556 Z M21.0602667,12.0782222 C20.4149333,12.0782222 19.8901333,11.5534222 19.8901333,10.9084444 C19.8901333,10.2634667 20.4149333,9.73866667 21.0602667,9.73866667 C21.7052444,9.73866667 22.2300444,10.2634667 22.2300444,10.9084444 C22.2300444,11.5534222 21.7052444,12.0782222 21.0602667,12.0782222 Z"></path>
            <path d="M15.9569778,13.1452444 C14.3832889,13.1452444 13.1018667,14.4259556 13.1018667,15.9996444 C13.1018667,17.5740444 14.3832889,18.8551111 15.9569778,18.8551111 C17.5313778,18.8551111 18.8117333,17.5740444 18.8117333,15.9996444 C18.8117333,14.4263111 17.5310222,13.1452444 15.9569778,13.1452444 Z"></path>
            <path d="M15.9569778,0 C7.14453333,0 0,7.14453333 0,15.9569778 C0,24.7694222 7.14453333,31.9139556 15.9569778,31.9139556 C24.7694222,31.9139556 31.9139556,24.7694222 31.9139556,15.9569778 C31.9139556,7.14453333 24.7694222,0 15.9569778,0 Z M25.5555556,20.7559111 C25.5555556,23.4264889 23.3834667,25.5985778 20.7128889,25.5985778 L11.2010667,25.5985778 C8.53084444,25.5985778 6.3584,23.4264889 6.3584,20.7559111 L6.3584,11.2440889 C6.3584,8.57386667 8.53084444,6.40142222 11.2010667,6.40142222 L20.7128889,6.40142222 C23.3834667,6.40142222 25.5555556,8.57386667 25.5555556,11.2440889 L25.5555556,20.7559111 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconInstagram
