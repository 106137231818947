import qs from 'querystring'

export const isWeb = queryString => {
  const query = qs.parse(queryString.substr(1))
  if (query.web === '') {
    return true
  }
  return false
}

export const isFooter = queryString => {
  const query = qs.parse(queryString.substr(1))
  if (query.footer === '') {
    return true
  }
  return false
}

export const getLn = queryString => {
  const query = qs.parse(queryString.substr(1))
  if (query.ln) {
    return query.ln
  }
  return 'en'
}

export const getSection = queryString => {
  const query = qs.parse(queryString.substr(1))
  if (query.s) {
    return query.s
  }
}

export const getQuestion = queryString => {
  const query = qs.parse(queryString.substr(1))
  if (query.q) {
    return query.q
  }
}

export const getContent = context => {
  const result = []

  context.keys().forEach(filename => {
    result.push({ ...context(filename), filename })
  })

  return result
}
