import React from 'react'
import Props from 'prop-types'
import { Link } from 'gatsby'

const Logo = ({ className, translates }) => (
  <Link to={`/${translates.slug !== 'en' ? `/${translates.slug}/` : '/'}`}>
    <svg
      width="1em"
      height="1em"
      className={className}
      viewBox="0 0 58 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-208.000000, -24.000000)" fill="currentColor">
          <g transform="translate(208.000000, 24.000000)">
            <path d="M27.3518313,0 C28.9441953,0 30.6015538,1.62711864 29.7735457,3.75322034 C28.5879294,6.79756342 4.71276879,28.5450847 4.71276879,28.5450847 C4.71276879,28.5450847 16.0867976,26.6847458 22.2287732,29.6840678 C23.6315701,30.3674576 23.2784472,31.4508135 23.2145224,31.5823729 C22.7480577,32.5423729 10.9786459,29.6461017 2.97891232,31.5823729 C1.76568257,31.8861017 0,31.1918644 0,29.559322 C0,27.9267797 1.08324084,26.8474576 1.62486127,26.5762712 C5.30766878,24.7323092 25.72697,3.75322034 25.72697,3.75322034 C25.72697,3.75322034 15.1010485,6.82847458 9.2244669,6.82847458 C6.49469998,6.82847458 6.77025527,3.25423729 10.8324084,2.71186441 C11.603307,2.58320281 15.2477273,2.29401752 18.6859046,1.62711864 C22.1240818,0.960219771 26.5149479,0 27.3518313,0 Z"></path>
            <path d="M56.8863929,16.3850847 C58.8958047,16.3850847 48.6212653,28.7620339 40.5457048,28.7620339 C39.4462153,28.7620339 36.8301887,27.8508475 36.8301887,23.7125424 C36.8301887,17.4481356 43.3133851,8.6779661 47.7871698,8.6779661 C50.5927636,8.6779661 52.6400888,11.9810169 52.6400888,13.499661 C52.6400888,16.3850847 48.4750277,19.2542373 45.4961154,20.3389831 C44.6914703,20.6319878 43.0588235,21.1525424 41.9755827,20.8813559 C40.1178246,24.3362712 39.7495228,26.56 41.7589345,26.56 C47.9388235,26.56 55.8627303,16.3850847 56.8863929,16.3850847 Z M49.7586681,12.8922034 C47.5596892,12.8922034 43.600444,16.5423729 43.3296337,18.1694915 C45.6423529,18.1694915 51.2372919,12.8922034 49.7586681,12.8922034 Z"></path>
            <path d="M42.0450921,16.4610169 C43.0308413,16.6128814 39.5428058,21.700339 34.9931943,21.0928814 C32.3771676,25.1932203 28.1687769,28.7620339 24.0362131,28.7620339 C13.4204529,28.7620339 24.372919,13.8305085 26.8102109,11.9322034 C29.2475028,10.0338983 29.7232275,8.6779661 33.2870899,8.6779661 C34.8036271,8.6779661 37.533394,9.66508475 37.533394,13.7654237 C37.533394,15.4738983 37.0784329,16.9166102 36.0547703,18.8908475 C39.5428058,18.9288136 41.8176116,16.4230508 42.0450921,16.4610169 Z M23.8845594,26.5220339 C26.0077115,26.5220339 28.8891321,23.5227119 30.9364573,20.1057627 C29.495747,19.080678 28.5858247,17.3342373 28.2825172,15.5498305 C26.9555472,16.7647458 22.5955028,21.0928814 22.5955028,24.9274576 C22.5955028,25.4969492 22.7471565,26.5220339 23.8845594,26.5220339 Z M32.2255139,17.4861017 C33.0975228,15.6257627 33.5145705,14.3728814 33.5145705,12.9301695 C33.5145705,10.7281356 32.2634273,10.2725424 31.618899,10.4244068 C29.2682664,11.0318644 30.4435827,16.6888136 32.2255139,17.4861017 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  </Link>
)

Logo.propTypes = {
  className: Props.string,
  translates: Props.shape({
    slug: Props.string,
  }).isRequired,
}

Logo.defaultProps = {
  className: '',
}

export default Logo
