import React, { Fragment } from 'react'

import config from 'config'
import IconFacebook from 'components/Icons/IconFacebook'
import IconInstagram from 'components/Icons/IconInstagram'
import IconTwitter from 'components/Icons/IconTwitter'

const Social = () => (
  <Fragment>
    <li>
      <a href={config.facebook} target="_blank" rel="noopener noreferrer">
        <IconFacebook />
      </a>
    </li>
    <li>
      <a href={config.twitter} target="_blank" rel="noopener noreferrer">
        <IconTwitter />
      </a>
    </li>
    <li>
      <a href={config.instagram} target="_blank" rel="noopener noreferrer">
        <IconInstagram />
      </a>
    </li>
  </Fragment>
)

export default Social
