import React from 'react'
import Props from 'prop-types'
import { compose, withState, withHandlers } from 'recompose'

import Menu from './Menu'
import { Nav, Logo, MobileToggle } from './styled'

const Navigation = ({
  toggleMenu,
  isOpen,
  isMobileVisible,
  changeOpenState,
  translates,
  ...props
}) => (
  <Nav {...props} isMobileVisible={isMobileVisible} role="navigation">
    <Logo {...props} isOpen={isOpen} translates={translates} />

    <Menu
      {...props}
      onScrollClick={() => changeOpenState(false)}
      isOpen={isOpen}
      translates={translates}
    />

    <MobileToggle {...props} isOpen={isOpen} onClick={toggleMenu} />
  </Nav>
)

Navigation.propTypes = {
  changeOpenState: Props.func.isRequired,
  isMobileVisible: Props.bool,
  isOpen: Props.bool.isRequired,
  toggleMenu: Props.func.isRequired,
  translates: Props.any,
}

Navigation.defaultProps = {
  isMobileVisible: false,
  translates: {},
}

export default compose(
  withState('isOpen', 'changeOpenState', false),
  withHandlers({
    toggleMenu: props => () => props.changeOpenState(prevVal => !prevVal),
  })
)(Navigation)
