import styled from 'styled-components'

export const Footer = styled.footer`
  color: white;
  padding: 4rem 0;
  margin-top: auto;
  background: ${props => props.theme.colors.darkGrey};

  a {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-areas:
    'help social'
    'menu copyright';

  ${props => props.theme.mq.small.css`
    display: flex;
    flex-direction: column;
  `}
`

export const Help = styled.div`
  grid-area: help;
  font-size: 2.4rem;
  line-height: 1.4;
  max-width: 36em;
  margin-bottom: 6rem;

  a {
    font-weight: bold;
  }

  ${props => props.theme.mq.small.css`
    margin-bottom: 8rem;
  `}
`

export const Copy = styled.p`
  margin: 0;
  font-size: 1.6rem;
  grid-area: copyright;

  ${props => props.theme.mq.small.css`
    text-align: center;
  `}

  ${props => props.theme.mq.mediumUp.css`
    text-align: right;
  `}
`

export const Menu = styled.ul`
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  grid-area: menu;

  ${props => props.theme.mq.small.css`
    display: none;
  `}

  li {
    margin-right: 2.4rem;
  }
`

export const Social = styled.ul`
  display: flex;
  justify-content: flex-end;
  grid-area: social;
  font-size: 3.2rem;

  ${props => props.theme.mq.small.css`
    display: none;
  `}

  li {
    margin-left: 1.5rem;

    a {
      transition: color 0.3s;
    }

    a:hover,
    a:focus,
    a:active {
      color: rgba(255, 255, 255, 0.8);
    }
  }
`
