import styled, { css } from 'styled-components'
import LogoBase from './Logo'

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.large}px;
  padding: 2.4rem 2rem;
  z-index: 20;

  ${props =>
    !props.absolute &&
    props.theme.mq.small.css`
    color: ${props.theme.colors.headings};
  `}

  ${props =>
    !props.isMobileVisible &&
    css`
      display: none;
    `}
`

export const Logo = styled(LogoBase)`
  font-size: 5.8rem;
  color: ${props => props.theme.colors.headings};

  ${props =>
    props.absolute &&
    css`
      color: white;
    `}

  ${props => props.theme.mq.small.css`
    font-size: 7.2rem;
    position: absolute;
    top: 1.8rem;
    color: ${props.isOpen ? props.theme.colors.headings : 'inherit'};
    z-index: 2000;
  `}
`

export const Ul = styled.ul`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.colors.primary};

  ${props =>
    props.absolute &&
    props.theme.mq.mediumUp.css`
    color: white;
  `}

  ${props => props.theme.mq.small.css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 2.4rem;

    &:before, &:after { content: ""; display: block; }
    &:before { flex: 4; }
    &:after  { flex: 7; }
  `}

  li {
    display: inline-block;

    ${props => props.theme.mq.mediumUp.css`
      margin-left: 3.2rem;
    `}

    ${props => props.theme.mq.small.css`
      display: block;
    `}

    a {
      text-decoration: none;

      &:hover,
      &:active {
        text-decoration: underline;
      }

      ${props => props.theme.mq.small.css`
        display: block;
        padding: 1rem 0;
      `}
    }
  }
`

export const Li = styled.li`
  ${props =>
    props.mobileOnly &&
    props.theme.mq.mediumUp.css`
    display: none !important;
  `}
`

export const MobileMenuContainer = styled.div`
  ${props => props.theme.mq.small.css`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0; left: 0;
    width: 100%; height: 100%;
    padding: 2rem;
    color: black;
    background: white;
    z-index: 1000;

    ${!props.isOpen &&
      css`
        display: none;
      `}
  `}
`

export const MobileToggle = styled.button`
  display: none;

  ${props => props.theme.mq.small.css`
    position: absolute;
    display: block;
    top: 3.6rem; right: 2rem;
    flex-direction: column;
    justify-content: space-around;
    width: 3rem; height: 3rem;
    padding: 0.34rem 0;
    appearance: none;
    border: 0 solid currentColor;
    border-width: 2px 0;
    cursor: pointer;
    color: ${
      props.absolute && !props.isOpen ? 'white' : props.theme.colors.primary
    };
    background: none;
    z-index: 2000;

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      margin: -1px 0;
      border-top: 2px solid currentColor;
    }

    &:before { top: 33.333%; }
    &:after { bottom: 33.333%; }

    ${props.isOpen &&
      css`
        top: 5rem;
        height: 0;
        border-color: transparent;

        &:before {
          transform: translateY(50%) rotateZ(-45deg);
        }

        &:after {
          transform: translateY(-50%) rotateZ(45deg);
        }
      `}
  `}
`

export const Social = styled.ul`
  display: flex;
  justify-content: center;
  grid-area: social;
  font-size: 3.2rem;
  color: #706777;

  ${props => props.theme.mq.mediumUp.css`
    display: none;
  `}

  li {
    margin: 0 0.8rem;
  }
`
