import React from 'react'
import Props from 'prop-types'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { Link } from 'gatsby'

const ScrollLink = ({ element, to, children, handleClick }) =>
  element ? (
    <a href={to} onClick={handleClick}>
      {children}
    </a>
  ) : (
    <Link to={`/${to}`}>{children}</Link>
  )

ScrollLink.propTypes = {
  children: Props.any,
  element: Props.any,
  handleClick: Props.func.isRequired,
  to: Props.string.isRequired,
}

ScrollLink.defaultProps = {
  children: null,
  element: null,
}

export default compose(
  withState('element', 'changeElementState', null),
  withHandlers({
    handleClick: ({ element, onScrollClick }) => evt => {
      evt.preventDefault()
      const target =
        typeof element === 'string' ? document.getElementById(element) : element

      target.scrollIntoView({ behavior: 'smooth' })

      if (typeof onScrollClick === 'function') {
        onScrollClick()
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.changeElementState(
        document.getElementById(this.props.to.replace('#', ''))
      )
    },
  })
)(ScrollLink)
