import { css } from 'styled-components'

export const breakpoints = {
  small: 320,
  medium: 750,
  large: 1064,
}

const mq = {
  largeDown: {
    css: (...args) => css`
      @media (max-width: ${breakpoints.xlarge - 1}px) {
        ${css(...args)};
      }
    `,
  },
  mediumDown: {
    css: (...args) => css`
      @media (max-width: ${breakpoints.large - 1}px) {
        ${css(...args)};
      }
    `,
  },

  ...Object.keys(breakpoints).reduce((acc, label) => {
    acc[`${label}Up`] = {
      css: (...args) => css`
        @media (min-width: ${label === 'small' ? 0 : breakpoints[label]}px) {
          ${css(...args)};
        }
      `,
    }
    return acc
  }, {}),

  small: {
    css: (...args) => css`
      @media (max-width: ${breakpoints.medium - 1}px) {
        ${css(...args)};
      }
    `,
  },
  medium: {
    css: (...args) => css`
      @media (min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large -
          1}px) {
        ${css(...args)};
      }
    `,
  },
  large: {
    css: (...args) => css`
      @media (min-width: ${breakpoints.large}px) {
        ${css(...args)};
      }
    `,
  },
}

export const theme = {
  colors: {
    primary: '#9F2FFE',
    text: '#706778',
    headings: '#41354D',
    darkGrey: '#41354B',
    lightGrey: '#ecebed',
    grey100: '#A09AA5',
    grey200: '#CFCCD2',
    grey300: '#706777',
    error: '#FE2F57',
  },
  sizes: breakpoints,
  font: 'proxima-nova, sans-serif',
  mq,
}

export default mq
